var columns = [{
  title: '排序',
  dataIndex: 'sort',
  key: 'sort',
  width: '10%',
  scopedSlots: {
    customRender: 'sort'
  }
}, {
  title: '封面',
  dataIndex: 'imageurl',
  key: 'imageurl',
  width: '10%',
  scopedSlots: {
    customRender: 'imageurl'
  }
}, {
  title: '链接',
  dataIndex: 'linkurl',
  key: 'linkurl',
  width: '15%',
  scopedSlots: {
    customRender: 'linkurl'
  }
}, {
  title: 'banner标题',
  dataIndex: 'bannertitle',
  key: 'bannertitle',
  width: '10%',
  scopedSlots: {
    customRender: 'bannertitle'
  }
}, {
  title: '语言',
  dataIndex: 'language',
  key: 'language',
  width: '10%',
  scopedSlots: {
    customRender: 'language'
  }
}, {
  title: '状态',
  dataIndex: 'effectstatus',
  key: 'effectstatus',
  width: '10%',
  scopedSlots: {
    customRender: 'effectstatus'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  // fixed: "right",
  width: '10%',
  scopedSlots: {
    customRender: 'action'
  }
}];
var configColumns = [{
  title: '类型',
  dataIndex: 'name',
  key: 'name',
  width: '33%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '状态',
  dataIndex: 'effectstatus',
  key: 'effectstatus',
  width: '33%',
  scopedSlots: {
    customRender: 'effectstatus'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  width: '34%',
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns, configColumns };